import i18n from '@/i18n'

export enum Sections {
  plan = 'plan',
  invitedDesigners = 'invitedDesigners',
  promotional = 'promotional',
  recommended = 'recommended',
  privacy = 'privacy',
  perks = 'perks',
  promoCode = 'promo',
}

export enum Extras {
  communication = 'communication',
  designRights = 'design-rights',
  getCloudAccessible = 'get-cloud-accessible',
  maxDesignerProposalEntries = 'max-designer-proposal-entries',
  personalDesignerInvitations = 'personal-designer-invitations',
  cloud = 'get-cloud-accessible',
  activeFor = 'active-for',
}

export enum PlanExtras {
  promote = 'promote',
  featured = 'featured',
  highlighted = 'highlighted',
  deal = 'deal',
  blind = 'blind',
  guaranteed = 'guaranteed',
  prize = 'prize',
  private = 'private',
  nda = 'nda',
  duration = 'duration',
  paidInvites = 'paid-invites',
}

export const ExtrasDescription: any = {
  [PlanExtras.promote]: {
    title: i18n.t('Promote'),
    description: i18n.t(
      'We will promote your contest to our registered designers on our social media. You will receive more unique designs in a shorter time.'
    ),
    section: Sections.promotional,
    options: {
      group: PlanExtras.deal,
      array: true,
    },
  },
  [PlanExtras.featured]: {
    title: i18n.t('Featured'),
    description: i18n.t(
      'We will post your project at the top of the list, so it will be immediately visible among other active contests. This will attract 40% more designers on average.'
    ),
    section: Sections.promotional,
    options: {
      group: PlanExtras.deal,
      array: true,
    },
  },
  [PlanExtras.highlighted]: {
    title: i18n.t('Highlighted'),
    description: i18n.t('We will highlight your contest tin orange to make it stand out.'),
    section: Sections.promotional,
    options: {
      group: PlanExtras.deal,
      array: true,
    },
  },
  [PlanExtras.deal]: {
    title: i18n.t('Don’t miss out!'),
    description: i18n.t('All options above for only 39€ and save 20%!'),
    section: Sections.promotional,
    options: {
      deal: true,
      array: true,
    },
  },
  [PlanExtras.blind]: {
    title: i18n.t('Blind'),
    description: i18n.t('Block designers from seeing each other entries. This will increase creativity.'),
    section: Sections.recommended,
    options: {
      array: true,
    },
  },
  [PlanExtras.guaranteed]: {
    title: i18n.t('Guaranteed'),
    description: i18n.t(
      'You will let the designers know that you are serious about your project and you wave the right to refund. This way you will gain all our best talent attention.'
    ),
    section: Sections.recommended,
    options: {
      array: true,
    },
  },
  [PlanExtras.prize]: {
    title: i18n.t('Prize for 2nd and 3rd places'),
    description: i18n.t(
      'Will usually attract 50% more designers, because second and third places will also be awarded.'
    ),
    section: Sections.recommended,
    options: {
      array: true,
    },
  },
  [PlanExtras.private]: {
    title: i18n.t('Private'),
    description: i18n.t('Hide from search engines, competitors and clients.'),
    section: Sections.privacy,
    options: {
      array: true,
    },
  },
  [PlanExtras.nda]: {
    title: 'NDA-Private',
    description: i18n.t(
      'Hide from search engines, competitors and clients, and every designer will sign a Non-Disclosure agreement, before seeing your contest details, which will keep your contest in absolute secrecy.'
    ),
    section: Sections.privacy,
    options: {
      array: true,
    },
  },
  [PlanExtras.duration]: {
    title: i18n.t('Duration'),
    description: i18n.t('Priority project, for those who want express turnaround.'),
    section: Sections.perks,
    options: {
      array: true,
    },
  },
  [PlanExtras.paidInvites]: {
    title: i18n.t('Paid Invites'),
    description: i18n.t(
      'Send paid invitations to designers and ask them to attend your contest. The designer will receive the payment only if he submits his design in limited time frame. Otherwise the invite is reimbursed to you.'
    ),
    section: Sections.perks,
    options: {
      array: true,
    },
  },
}
